import * as i0 from '@angular/core';
import { EventEmitter, Directive, ContentChildren, Input, Output, HostListener, ContentChild, Component } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/forms';
import { FormArray, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
const _c0 = ["otpInputElement"];
const _c1 = a0 => ({
  "ngx-blinking-cursor": a0
});
const _c2 = (a0, a1, a2, a3) => ({
  "ngx-otp-input-disabled": a0,
  "ngx-otp-input-filled": a1,
  "ngx-otp-input-success": a2,
  "ngx-otp-input-failed": a3
});
function NgxOtpInputComponent_input_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 3, 0);
  }
  if (rf & 2) {
    const control_r1 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", control_r1.value)("type", ctx_r2.inputType)("inputMode", ctx_r2.ngxOtpOptions.inputMode)("disabled", ctx_r2.disabled)("ngClass", i0.ɵɵpureFunction4(5, _c2, ctx_r2.disabled, ctx_r2.isInputFilled(i_r2), ctx_r2.isOTPSuccess, ctx_r2.isOTPFailed));
  }
}
class PasteDirective {
  constructor() {
    this.handlePaste = new EventEmitter();
  }
  onPaste(event) {
    event.preventDefault();
    const clipboardData = event.clipboardData?.getData('text');
    if (clipboardData && this.regexp.test(clipboardData)) {
      const values = clipboardData.split('');
      this.inputs.forEach((input, index) => {
        if (values[index]) {
          input.nativeElement.value = values[index];
        }
      });
      this.handlePaste.emit(values);
    }
  }
  static {
    this.ɵfac = function PasteDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PasteDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PasteDirective,
      selectors: [["", "ngxOtpPaste", ""]],
      contentQueries: function PasteDirective_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, _c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputs = _t);
        }
      },
      hostBindings: function PasteDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("paste", function PasteDirective_paste_HostBindingHandler($event) {
            return ctx.onPaste($event);
          });
        }
      },
      inputs: {
        regexp: "regexp"
      },
      outputs: {
        handlePaste: "handlePaste"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PasteDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[ngxOtpPaste]'
    }]
  }], null, {
    inputs: [{
      type: ContentChildren,
      args: ['otpInputElement', {
        descendants: true
      }]
    }],
    regexp: [{
      type: Input
    }],
    handlePaste: [{
      type: Output
    }],
    onPaste: [{
      type: HostListener,
      args: ['paste', ['$event']]
    }]
  });
})();
class AutoFocusDirective {
  ngAfterContentInit() {
    if (this.ngxAutoFocus && this.firstInput) {
      this.firstInput.nativeElement.focus();
    }
  }
  static {
    this.ɵfac = function AutoFocusDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AutoFocusDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AutoFocusDirective,
      selectors: [["", "ngxAutoFocus", ""]],
      contentQueries: function AutoFocusDirective_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, _c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.firstInput = _t.first);
        }
      },
      inputs: {
        ngxAutoFocus: "ngxAutoFocus"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoFocusDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[ngxAutoFocus]'
    }]
  }], null, {
    firstInput: [{
      type: ContentChild,
      args: ['otpInputElement', {
        static: false
      }]
    }],
    ngxAutoFocus: [{
      type: Input
    }]
  });
})();
class InputNavigationsDirective {
  constructor() {
    this.inputsArray = [];
    this.valueChange = new EventEmitter();
  }
  ngAfterContentInit() {
    this.inputsArray = this.inputs.toArray();
  }
  findInputIndex(target) {
    return this.inputsArray.findIndex(input => input.nativeElement === target);
  }
  setFocus(index) {
    if (index >= 0 && index < this.inputs.length) {
      this.inputsArray[index].nativeElement.focus();
    }
  }
  onArrowLeft(event) {
    const index = this.findInputIndex(event.target);
    if (index > 0) {
      this.setFocus(index - 1);
    }
  }
  onArrowRight(event) {
    const index = this.findInputIndex(event.target);
    if (index < this.inputs.length - 1) {
      this.setFocus(index + 1);
    }
  }
  onBackspace(event) {
    const index = this.findInputIndex(event.target);
    if (index >= 0) {
      this.valueChange.emit([index, '']);
      this.setFocus(index - 1);
      event.preventDefault();
    }
  }
  onKeyUp(event) {
    const index = this.findInputIndex(event.target);
    if (event.data?.match(this.regexp)) {
      this.valueChange.emit([index, event.data]);
      this.setFocus(index + 1);
    } else {
      this.inputsArray[index].nativeElement.value = '';
    }
  }
  static {
    this.ɵfac = function InputNavigationsDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || InputNavigationsDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: InputNavigationsDirective,
      selectors: [["", "ngxInputNavigations", ""]],
      contentQueries: function InputNavigationsDirective_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, _c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputs = _t);
        }
      },
      hostBindings: function InputNavigationsDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown.arrowLeft", function InputNavigationsDirective_keydown_arrowLeft_HostBindingHandler($event) {
            return ctx.onArrowLeft($event);
          })("keydown.arrowRight", function InputNavigationsDirective_keydown_arrowRight_HostBindingHandler($event) {
            return ctx.onArrowRight($event);
          })("keydown.backspace", function InputNavigationsDirective_keydown_backspace_HostBindingHandler($event) {
            return ctx.onBackspace($event);
          })("input", function InputNavigationsDirective_input_HostBindingHandler($event) {
            return ctx.onKeyUp($event);
          });
        }
      },
      inputs: {
        regexp: "regexp"
      },
      outputs: {
        valueChange: "valueChange"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputNavigationsDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[ngxInputNavigations]'
    }]
  }], null, {
    inputs: [{
      type: ContentChildren,
      args: ['otpInputElement', {
        descendants: true
      }]
    }],
    regexp: [{
      type: Input
    }],
    valueChange: [{
      type: Output
    }],
    onArrowLeft: [{
      type: HostListener,
      args: ['keydown.arrowLeft', ['$event']]
    }],
    onArrowRight: [{
      type: HostListener,
      args: ['keydown.arrowRight', ['$event']]
    }],
    onBackspace: [{
      type: HostListener,
      args: ['keydown.backspace', ['$event']]
    }],
    onKeyUp: [{
      type: HostListener,
      args: ['input', ['$event']]
    }]
  });
})();
class AutoBlurDirective {
  constructor() {
    this.inputHTMLElements = [];
  }
  ngOnChanges(changes) {
    if (this.ngxAutoBlur && this.inputHTMLElements.length > 0 && changes['isFormValid'].currentValue) {
      this.inputHTMLElements.forEach(input => {
        input.blur();
      });
    }
  }
  ngAfterContentInit() {
    this.inputs.forEach(input => {
      this.inputHTMLElements.push(input.nativeElement);
    });
  }
  static {
    this.ɵfac = function AutoBlurDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AutoBlurDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AutoBlurDirective,
      selectors: [["", "ngxAutoBlur", ""]],
      contentQueries: function AutoBlurDirective_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, _c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputs = _t);
        }
      },
      inputs: {
        ngxAutoBlur: "ngxAutoBlur",
        isFormValid: "isFormValid"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoBlurDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[ngxAutoBlur]'
    }]
  }], null, {
    inputs: [{
      type: ContentChildren,
      args: ['otpInputElement', {
        descendants: true
      }]
    }],
    ngxAutoBlur: [{
      type: Input
    }],
    isFormValid: [{
      type: Input
    }]
  });
})();
class AriaLabelsDirective {
  ngAfterContentInit() {
    this.setAriaLabelsAttrs();
  }
  getDefaultAriaLabelText(index) {
    return `One Time Password Input Number ${index + 1}`;
  }
  setAriaLabelsAttrs() {
    this.inputs.forEach((input, index) => {
      input.nativeElement.setAttribute('aria-label', this.ngxOtpAriaLabels[index] ?? this.getDefaultAriaLabelText(index));
    });
  }
  static {
    this.ɵfac = function AriaLabelsDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AriaLabelsDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AriaLabelsDirective,
      selectors: [["", "ngxOtpAriaLabels", ""]],
      contentQueries: function AriaLabelsDirective_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, _c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputs = _t);
        }
      },
      inputs: {
        ngxOtpAriaLabels: "ngxOtpAriaLabels"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AriaLabelsDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[ngxOtpAriaLabels]'
    }]
  }], null, {
    inputs: [{
      type: ContentChildren,
      args: ['otpInputElement', {
        descendants: true
      }]
    }],
    ngxOtpAriaLabels: [{
      type: Input
    }]
  });
})();
const defaultOptions = {
  otpLength: 6,
  autoFocus: true,
  autoBlur: true,
  hideInputValues: false,
  regexp: /^[0-9]+$/,
  showBlinkingCursor: true,
  ariaLabels: [],
  inputMode: 'numeric'
};
var NgxOtpStatus;
(function (NgxOtpStatus) {
  NgxOtpStatus["SUCCESS"] = "success";
  NgxOtpStatus["FAILED"] = "failed";
})(NgxOtpStatus || (NgxOtpStatus = {}));
class NgxOtpInputComponent {
  constructor() {
    this.ngxOtpOptions = defaultOptions;
    this.disabled = false;
    this.otpChange = new EventEmitter();
    this.otpComplete = new EventEmitter();
  }
  set options(customOptions) {
    this.ngxOtpOptions = {
      ...defaultOptions,
      ...customOptions
    };
  }
  // For testing purposes
  get ngxOtpOptionsInUse() {
    return this.ngxOtpOptions;
  }
  get inputType() {
    return this.ngxOtpOptions.hideInputValues ? 'password' : 'text';
  }
  get isOTPSuccess() {
    return this.status === NgxOtpStatus.SUCCESS;
  }
  get isOTPFailed() {
    return this.status === NgxOtpStatus.FAILED;
  }
  ngOnInit() {
    this.initOtpInputArray();
  }
  ngOnChanges(changes) {
    const otpChange = changes['otp'];
    if (otpChange?.currentValue) {
      if (!otpChange.firstChange) {
        this.setInitialOtp(otpChange.currentValue);
      } else {
        this.ngxOtpOptions.autoFocus = false;
      }
    }
  }
  initOtpInputArray() {
    this.ngxOtpInputArray = new FormArray(Array.from({
      length: this.ngxOtpOptions.otpLength
    }, () => new FormControl('', Validators.required)));
    if (this.otp) {
      this.setInitialOtp(this.otp);
    }
  }
  setInitialOtp(otp) {
    if (this.ngxOtpOptions.regexp.test(otp)) {
      const otpValueArray = otp.split('');
      otpValueArray.forEach((value, index) => {
        this.ngxOtpInputArray.controls[index].setValue(value ?? '');
      });
      this.emitOtpValueChange();
      if (otpValueArray.length !== this.ngxOtpOptions.otpLength) {
        console.warn('OTP length does not match the provided otpLength option!');
      }
    } else {
      throw new Error('Invalid OTP provided for the component <ngx-otp-input>');
    }
  }
  handleInputChanges($event) {
    const [index, value] = $event;
    this.ngxOtpInputArray.controls[index].setValue(value);
    this.emitOtpValueChange();
  }
  handlePasteChange($event) {
    if ($event.length === this.ngxOtpOptions.otpLength) {
      this.ngxOtpInputArray.setValue($event);
    } else {
      $event.map((value, index) => {
        this.ngxOtpInputArray.controls[index].setValue(value);
      });
    }
    this.emitOtpValueChange();
  }
  emitOtpValueChange() {
    this.otpChange.emit(this.ngxOtpInputArray.value);
    if (this.ngxOtpInputArray.valid) {
      this.otpComplete.emit(this.ngxOtpInputArray.value.join(''));
    }
  }
  isInputFilled(index) {
    return !!this.ngxOtpInputArray.controls[index].value;
  }
  reset() {
    this.ngxOtpInputArray.reset();
  }
  static {
    this.ɵfac = function NgxOtpInputComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxOtpInputComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxOtpInputComponent,
      selectors: [["ngx-otp-input"]],
      inputs: {
        options: "options",
        status: "status",
        disabled: "disabled",
        otp: "otp"
      },
      outputs: {
        otpChange: "otpChange",
        otpComplete: "otpComplete"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 9,
      consts: [["otpInputElement", ""], ["ngxOtpPaste", "", "ngxInputNavigations", "", "data-testid", "ngx-otp-input-form", 1, "ngx-otp-input-form", 3, "valueChange", "handlePaste", "regexp", "isFormValid", "ngxAutoFocus", "ngxAutoBlur", "ngxOtpAriaLabels", "ngClass"], ["class", "ngx-otp-input-box", "maxlength", "1", "spellcheck", "false", "autocomplete", "off", "autocapitalize", "off", "autocorrect", "off", "data-testid", "ngx-otp-input-box", 3, "value", "type", "inputMode", "disabled", "ngClass", 4, "ngFor", "ngForOf"], ["maxlength", "1", "spellcheck", "false", "autocomplete", "off", "autocapitalize", "off", "autocorrect", "off", "data-testid", "ngx-otp-input-box", 1, "ngx-otp-input-box", 3, "value", "type", "inputMode", "disabled", "ngClass"]],
      template: function NgxOtpInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "form", 1);
          i0.ɵɵlistener("valueChange", function NgxOtpInputComponent_Template_form_valueChange_0_listener($event) {
            return ctx.handleInputChanges($event);
          })("handlePaste", function NgxOtpInputComponent_Template_form_handlePaste_0_listener($event) {
            return ctx.handlePasteChange($event);
          });
          i0.ɵɵtemplate(1, NgxOtpInputComponent_input_1_Template, 2, 10, "input", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("regexp", ctx.ngxOtpOptions.regexp)("isFormValid", ctx.ngxOtpInputArray.valid)("ngxAutoFocus", ctx.ngxOtpOptions.autoFocus)("ngxAutoBlur", ctx.ngxOtpOptions.autoBlur)("ngxOtpAriaLabels", ctx.ngxOtpOptions.ariaLabels)("ngClass", i0.ɵɵpureFunction1(7, _c1, ctx.ngxOtpOptions.showBlinkingCursor));
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.ngxOtpInputArray.controls);
        }
      },
      dependencies: [CommonModule, i1.NgClass, i1.NgForOf, ReactiveFormsModule, i2.ɵNgNoValidate, i2.NgControlStatusGroup, PasteDirective, AutoFocusDirective, InputNavigationsDirective, AutoBlurDirective, AriaLabelsDirective],
      styles: [".ngx-otp-input-form[_ngcontent-%COMP%]{display:inline-flex;gap:.5rem;caret-color:transparent}.ngx-blinking-cursor[_ngcontent-%COMP%]{caret-color:initial}.ngx-otp-input-box[_ngcontent-%COMP%]{width:30px;height:35px;padding:.5rem;font-size:1.5rem;text-align:center;border:1px solid #c4c4c4;border-radius:.5rem;outline:none}.ngx-otp-input-box[_ngcontent-%COMP%]:focus{border-color:#007bff}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxOtpInputComponent, [{
    type: Component,
    args: [{
      standalone: true,
      imports: [CommonModule, ReactiveFormsModule, PasteDirective, AutoFocusDirective, InputNavigationsDirective, AutoBlurDirective, AriaLabelsDirective],
      selector: 'ngx-otp-input',
      template: "<form\n  ngxOtpPaste\n  ngxInputNavigations\n  [regexp]=\"ngxOtpOptions.regexp!\"\n  [isFormValid]=\"ngxOtpInputArray.valid\"\n  [ngxAutoFocus]=\"ngxOtpOptions.autoFocus!\"\n  [ngxAutoBlur]=\"ngxOtpOptions.autoBlur!\"\n  [ngxOtpAriaLabels]=\"ngxOtpOptions.ariaLabels!\"\n  [ngClass]=\"{\n    'ngx-blinking-cursor': ngxOtpOptions.showBlinkingCursor\n  }\"\n  (valueChange)=\"handleInputChanges($event)\"\n  (handlePaste)=\"handlePasteChange($event)\"\n  class=\"ngx-otp-input-form\"\n  data-testid=\"ngx-otp-input-form\"\n>\n  <input\n    *ngFor=\"let control of ngxOtpInputArray.controls; let i = index\"\n    #otpInputElement\n    [value]=\"control.value\"\n    [type]=\"inputType\"\n    [inputMode]=\"ngxOtpOptions.inputMode\"\n    [disabled]=\"disabled\"\n    [ngClass]=\"{\n      'ngx-otp-input-disabled': disabled,\n      'ngx-otp-input-filled': isInputFilled(i),\n      'ngx-otp-input-success': isOTPSuccess,\n      'ngx-otp-input-failed': isOTPFailed\n    }\"\n    class=\"ngx-otp-input-box\"\n    maxlength=\"1\"\n    spellcheck=\"false\"\n    autocomplete=\"off\"\n    autocapitalize=\"off\"\n    autocorrect=\"off\"\n    data-testid=\"ngx-otp-input-box\"\n  />\n</form>\n",
      styles: [".ngx-otp-input-form{display:inline-flex;gap:.5rem;caret-color:transparent}.ngx-blinking-cursor{caret-color:initial}.ngx-otp-input-box{width:30px;height:35px;padding:.5rem;font-size:1.5rem;text-align:center;border:1px solid #c4c4c4;border-radius:.5rem;outline:none}.ngx-otp-input-box:focus{border-color:#007bff}\n"]
    }]
  }], null, {
    options: [{
      type: Input
    }],
    status: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    otp: [{
      type: Input
    }],
    otpChange: [{
      type: Output
    }],
    otpComplete: [{
      type: Output
    }]
  });
})();

/*
 * Public API Surface of ngx-otp-input
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxOtpInputComponent, NgxOtpStatus };
